import React from "react";
import { useHistory } from "react-router-dom";

import {
  IconButton,
  Drawer,
  SwipeableDrawer,
  ListItem,
  ListItemIcon,
  ListItemText,
  Card,
  CardContent,
  CardActions,
  Typography,
  Hidden,
  Box,
  Badge,
  Menu,
  Button,
  Tooltip,
} from "@material-ui/core";
import Skeleton from '@material-ui/lab/Skeleton';
import ReportProblemOutlinedIcon from "@material-ui/icons/ReportProblemOutlined";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import GetAppIcon from "@material-ui/icons/GetApp";
import ReplyIcon from "@material-ui/icons/Reply";

// Components
import ProfileMenu from "app/components/navs/dropdowns/profile";
import ReportProblemDialog from "app/components/Modals/Report";
import ModuleAccordion from "app/components/accordions/Module/model-1";

import { useStyles } from "./styles";
import { useAuth } from "app/contexts/AuthContext";

import SchoolIcon from '@material-ui/icons/SchoolOutlined';
import GroupAddIcon from '@material-ui/icons/GroupAddOutlined';
import NewReleasesIcon from '@material-ui/icons/NewReleasesOutlined';
import { useScopes } from "app/contexts/ScopesContext";


export default (props) => {
  const {
    curriculum,
    progress,
    lesson,
    lessonSlug,
    isLoadingCurriculum,
    isLoadingProgress,
  } = props
  const classes = useStyles();
  const { user } = useAuth()
  const { scopes } = useScopes()
  const history = useHistory();


  const getAttachment = (link) => {
    window.open(link);
  };

  function UpperMenu() {
    const [openAttachmentMenu, setOpenAttachmentMenu] = React.useState(null);
    const handleAttachmentMenuClick = (event) => {
      setOpenAttachmentMenu(event.currentTarget);
    };
    const handleAttachmentMenuClose = () => {
      setOpenAttachmentMenu(null);
    };

    return (
      <Box
        component="div"
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Tooltip title="Voltar ao curso" arrow>
          <IconButton href="../." aria-label="back">
            <ReplyIcon className={classes.upperMenu} />
          </IconButton>
        </Tooltip>
        {/*<Tooltip title="Reportar um problema" arrow>
          <IconButton
            aria-label="warning"
            onClick={() => setOpenReportDialog(true)}
          >
            <ReportProblemOutlinedIcon className={classes.upperMenu} />
          </IconButton>
        </Tooltip>*/}
        <Tooltip
          title={
            lesson?.attachments?.length === 0
              ? "Nenhum material de apoio"
              : "Materiais de apoio"
          }
          arrow
        >
          <IconButton
            aria-label="attachments"
            aria-haspopup="true"
            onClick={handleAttachmentMenuClick}
          >
            <Badge
              color="secondary"
              badgeContent={
                lesson?.attachments
                  ? `${lesson.attachments.length}`
                  : ".."
              }
            >
              <AttachFileIcon className={classes.upperMenu} />
            </Badge>
          </IconButton>
        </Tooltip>
        <Tooltip title="Indique e ganhe" arrow>
          <IconButton onClick={() => history.push('/dashboard/referrals')}>
            <GroupAddIcon className={classes.upperMenu} />
          </IconButton>
        </Tooltip>
        <Tooltip title="Conheça Kultivi+" arrow>
          <IconButton href='https://planos.kultivi.com/?utm_source=app.kultivi.com&utm_medium=nav-profile' target='_blank'>
            <NewReleasesIcon className={classes.upperMenu} />
          </IconButton>
        </Tooltip>
        <ProfileMenu />
        {lesson?.attachments?.length > 0 && (
          <Menu
            id="menu-account"
            anchorEl={openAttachmentMenu}
            keepMounted
            open={Boolean(openAttachmentMenu)}
            onClose={handleAttachmentMenuClose}
          >
            {lesson.attachments.map((item, index) => {
              return (
                <ListItem
                  key={index}
                  button
                  onClick={() => getAttachment(item.link)}
                >
                  <ListItemIcon>
                    <GetAppIcon />
                  </ListItemIcon>
                  <ListItemText primary={`Material de apoio ${++index}`} />
                </ListItem>
              );
            })}
          </Menu>
        )}
      </Box>
    );
  }

  function SummaryAndMindMapNav(props) {
    return (
      <Box component="div" display="flex">
        {
          props.summaryLink &&
            <Button
            target='_blank'
            href={props.summaryLink}
            className={[classes.BtnNavClasses, classes.BtnNavClassesFeatured].join(' ')}
          >
            RESUMO DA AULA
          </Button>
        }
        {
          props.mindMapLink &&
          <Button
            target='_blank'
            href={props.mindMapLink}
            className={[classes.BtnNavClasses, classes.BtnNavClassesFeatured].join(' ')}
          >
            MAPA MENTAL
          </Button>
        }
      </Box>
    );
  }

  function NavClasses(props) {
    return (
      <Box component="div" display="flex">
        <Button
          disabled={!props.previous}
          href={`./${props.previous?.slug}`}
          className={[classes.BtnNavClasses, classes.BtnNavClassesNeutral].join(' ')}
        >
          Aula anterior
        </Button>
        <Button
          disabled={!props.next}
          href={`./${props.next?.slug}`}
          className={[classes.BtnNavClasses, classes.BtnNavClassesNeutral].join(' ')}
        >
          Próxima aula
        </Button>
      </Box>
    );
  }

  function PremiumSeller() {
    return (
      <Box component="div" display="flex">
        <Card className={classes.premiumCard}>
          <CardContent>
            <Typography component="h2" className={classes.premiumCardTitle}>
              Dê um passo a mais com o Kultivi+
            </Typography>
            <Typography
              component="p"
              variant="body2"
              className={classes.premiumCardCopy}
            >
              E-books exclusivos, simulados e novas funcionalidades para você
              aprender de forma mais rápida e divertida.
            </Typography>
          </CardContent>
          <CardActions>
            <Button
              href="https://planos.kultivi.com/?utm_source=app.kultivi.com&utm_medium=nav-box-classes"
              target="_blank"
              size="small"
              className={classes.premiumCardAction}
            >
              QUERO CONHECER
            </Button>
          </CardActions>
        </Card>
      </Box>
    );
  }

  const options = (
    <React.Fragment>
      <UpperMenu />
      <SummaryAndMindMapNav {...lesson} />
      {!scopes.includes('student_no_ads_display') && <PremiumSeller />}
      <NavClasses {...lesson} />
      {
        isLoadingCurriculum && isLoadingProgress ?
        [0, 1, 2, 4, 5, 6, 7].map((item, index) => <Skeleton key={index} animation="wave" variant="rect" height={50} style={{ marginBottom: 1, backgroundColor: 'rgba(158,207,250,0.1)' }} />) :
        curriculum?.map((module, index) => {
          return (
            <ModuleAccordion
              key={index}
              {...module}
              progress={progress}
              currentClass={lessonSlug}
            />
          );
        })
      }
    </React.Fragment>
  );

  const [openReportDialog, setOpenReportDialog] = React.useState(false);
  const closeReportDialog = () => setOpenReportDialog(false);

  return (
    <React.Fragment>
      <nav>
        <Hidden mdUp implementation="css">
          <SwipeableDrawer
            variant="temporary"
            anchor="right"
            open={props.open}
            onClose={props.toggleDrawer(false)}
            onOpen={props.toggleDrawer(true)}
            className={classes.root}
            classes={{ paper: classes.paper }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {options}
          </SwipeableDrawer>
        </Hidden>
        <Hidden mdDown implementation="css">
          <Drawer
            anchor="right"
            variant="permanent"
            className={classes.root}
            classes={{ paper: classes.paper }}
          >
            {options}
          </Drawer>
        </Hidden>
      </nav>
      <ReportProblemDialog
        open={openReportDialog}
        onClose={closeReportDialog}
      />
    </React.Fragment>
  );
};

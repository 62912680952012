import React from 'react';
import { useHistory } from "react-router-dom";

import {
  Toolbar,
  Typography,
  Box,
  ListItemIcon,
  Menu,
  MenuItem,
  Hidden,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import ProfileMenu from "app/components/navs/dropdowns/profile";
import useStyles from './styles';
import {
  sectionEstudar,
  sectionAprofundar,
  sectionPlus
} from './options-menu'

import SchoolIcon from '@material-ui/icons/SchoolOutlined';
import GroupAddIcon from '@material-ui/icons/GroupAddOutlined';
import NewReleasesIcon from '@material-ui/icons/NewReleasesOutlined';

const ToolBarDesktop = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { Logo, gotToPage } = props
  const [menuPosition, setMenuPosition] = React.useState({ top: 0, left: 0 });
  const [anchorElArray, setAnchorElArray] = React.useState([]);


  const handleClick = (index, event) => {
    setMenuPosition({
      top: event.currentTarget.offsetTop + 20,
      left: event.currentTarget.offsetLeft,
    });
    const newAnchorElArray = [...anchorElArray];
    newAnchorElArray[index] = event.currentTarget;
    setAnchorElArray(newAnchorElArray);
  };

  const handleMenuClose = (index) => {
    const newAnchorElArray = [...anchorElArray];
    newAnchorElArray[index] = null;
    setAnchorElArray(newAnchorElArray);
  };

  const goTo = (index, url, target) => {
    handleMenuClose(index);
    gotToPage(url, target)
  }


  function RenderMenu({title, options, index}) {
    const anchorEl = anchorElArray[index];
    return (
      <div>
          <MenuItem
            onClick={(event) => handleClick(index, event)}
            className={classes.optionMenu}
          >
            {title} <ExpandMoreIcon style={{marginLeft: 'auto' }} />
          </MenuItem>
          <Menu
            id={`menu_${title}`}
            keepMounted
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => handleMenuClose(index)}
            style={{ top: menuPosition.top, left: menuPosition.left }}
            classes={{
              paper: classes.menu,
              list: classes.menuList,
            }}
          >

            {options.map((option) => {
              return (
                <MenuItem
                  onClick={() => goTo(index, option.url, option.target)}
                  className={[classes.subMenuItem, option.featured && classes.subMenuItemFeatured].join(' ')}
                  style={{whiteSpace: 'normal'}}
                  disabled={option.disabled ?? false}
                  divider={option.divider ?? false}
                >
                  <ListItemIcon>
                    {option.icon}
                  </ListItemIcon>
                    <Box>
                      <Typography className={classes.subItemTitle}>{option.title}</Typography>
                      <Box
                        className={classes.subItemDescription}
                      >{option.description}</Box>
                    </Box>
                </MenuItem>
              )
            })}
          </Menu>
      </div>
    )
  }

  return (
    <Hidden mdDown>
      <Toolbar>
        <Logo />
        {/* <MenuItem
          onClick={() => history.push('/dashboard')}
          className={classes.optionMenu}
        >Início</MenuItem> */}
        {/* <MenuItem
          onClick={() => history.push('/dashboard/enrollments')}
          className={classes.optionMenu}
        >Meus Cursos</MenuItem> */}
        <MenuItem
          onClick={() => history.push('/dashboard/ebooks')}
          className={classes.optionMenu}
        >
          Ebooks
        </MenuItem>
        <RenderMenu options={sectionEstudar} title="Estudar" index={0} />
        <RenderMenu options={sectionAprofundar} title="Aprofundar" index={1} />
        <RenderMenu options={sectionPlus} title="Apoie" index={2} />
        <Box
          ml="auto"
          component="div"
          display="flex"
          flexDirection="row"
          alignItems="center"
          className={classes.menuItem}
        >
          <Tooltip title="Meus cursos" arrow>
            <IconButton onClick={() => history.push('/dashboard/enrollments')} className={classes.iconButton} >
              <SchoolIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Indique e ganhe" arrow>
            <IconButton onClick={() => history.push('/dashboard/referrals')} className={classes.iconButton}>
              <GroupAddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Conheça Kultivi+" arrow>
            <IconButton href='https://planos.kultivi.com/?utm_source=app.kultivi.com&utm_medium=nav-profile' target='_blank' className={classes.iconButton}>
              <NewReleasesIcon />
            </IconButton>
          </Tooltip>
          <ProfileMenu />
        </Box>
      </Toolbar>
    </Hidden>
  );
};

export default ToolBarDesktop;

import React from 'react';
import { useHistory } from "react-router-dom";
import {
  Toolbar,
  Box,
  ListItemIcon,
  Hidden,
  SwipeableDrawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Collapse,
  Tooltip,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import MenuIcon from '@material-ui/icons/Menu';
import ProfileMenu from "app/components/navs/dropdowns/profile";
import useStyles from './styles';
import {
  sectionEstudar,
  sectionAprofundar,
  sectionPlus
} from './options-menu'

import SchoolIcon from '@material-ui/icons/SchoolOutlined';
import GroupAddIcon from '@material-ui/icons/GroupAddOutlined';
import NewReleasesIcon from '@material-ui/icons/NewReleasesOutlined';

const ToolBarMobile = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { Logo, gotToPage } = props
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [anchorElArray, setAnchorElArray] = React.useState([]);

  const handleClick = (index, event) => {
    const newAnchorElArray = [...anchorElArray.map((anchor) => false)];
    if(anchorElArray[index] !== true)
      newAnchorElArray[index] = !!event.currentTarget;
    setAnchorElArray(newAnchorElArray);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(open);
  };

  const goTo = (index, url, target) => {
    setAnchorElArray([]);
    gotToPage(url, target)
    setOpenDrawer(false);
  }


  function RenderMenu({title, options, index}) {
    const anchorEl = anchorElArray[index];
    return (
      <div>
        <List
          component="nav"
          aria-labelledby="nested-list-subheader"
          // className={classes.root}
        >
          <ListItem button onClick={(event) => handleClick(index, event)}>
            <ListItemText primary={title} />
            {Boolean(anchorEl) ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </ListItem>
          <Collapse
            in={Boolean(anchorEl)}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {options.map((option) => {
                return (
                  <ListItem
                    button
                    className={classes.nested}
                    disabled={option.disabled ?? false}
                    onClick={() => goTo(index, option.url, option.target)}
                  >
                    <ListItemIcon>{option.icon}</ListItemIcon>
                    <ListItemText primary={option.title} />
                  </ListItem>
              )})}
            </List>
          </Collapse>
        </List>
      </div>
    )
  }

  return (
    <Hidden mdUp>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer(true)}
            edge="start"
          >
            <MenuIcon />
          </IconButton>
          <Logo />
          <Box
            ml="auto"
            component="div"
            display="flex"
            flexDirection="row"
            alignItems="center"
            className={classes.menuItem}
          >
            <Tooltip title="Meus cursos">
              <IconButton onClick={() => history.push('/dashboard/enrollments')} className={classes.iconButton} >
                <SchoolIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Indique e ganhe">
              <IconButton onClick={() => history.push('/dashboard/referrals')} className={classes.iconButton}>
                <GroupAddIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Conheça Kultivi+">
              <IconButton href='https://planos.kultivi.com/?utm_source=app.kultivi.com&utm_medium=nav-profile' target='_blank' className={classes.iconButton}>
                <NewReleasesIcon />
              </IconButton>
            </Tooltip>
            <ProfileMenu />
          </Box>
        </Toolbar>
      <SwipeableDrawer
        variant="temporary"
        anchor="left"
        open={openDrawer}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        className={classes.drawerMenu}
        ModalProps={{
          keepMounted: true
        }}
      >
        <ListItem
          button
          onClick={() => history.push('/dashboard')}
        >
          <ListItemText primary="Início" />
        </ListItem>
        <ListItem
          button
          onClick={() => history.push('/dashboard/enrollments')}
        >
          <ListItemText primary="Meus Cursos" />
        </ListItem>
        <ListItem
          button
          onClick={() => history.push('/dashboard/ebooks')}
        >
          <ListItemText primary="Ebooks" />
        </ListItem>
        <RenderMenu options={sectionEstudar} title="Estudar" index={0} />
        <RenderMenu options={sectionAprofundar} title="Aprofundar" index={1} />
        <RenderMenu options={sectionPlus} title="Apoie" index={2} />
      </SwipeableDrawer>
    </Hidden>
  );
};

export default ToolBarMobile;
